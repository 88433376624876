import firebase from "firebase/app";

export default class FirebaseResource {
  async getLanding(node) {
    try {
      const snapshot = await firebase
        .database()
        .ref(`knqun/${node}`)
        .once("value");

      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.warn(`No data found for node: ${node}`);
        return null;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
  async getPageBySlug(slug) {
    try {
      const snapshot = await firebase
        .database()
        .ref("knqun/pages")
        .orderByChild("slug")
        .equalTo(slug)
        .once("value");

      if (snapshot.exists()) {
        const pages = Object.values(snapshot.val());
        const pageData = pages.find((page) => page.slug === slug);
        return pageData;
      } else {
        console.warn(`No data found for page with slug: ${slug}`);
        return null;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}
