<template>
  <section class="services__container">
    <div class="container">
      <div class="services">
        <div v-for="(item, i) in data" :key="i" class="services__item">
          <div class="services__cover">
            <div class="services__cover--wrap">
              <img
                class="services__cover--img"
                :src="
                  `https://firebasestorage.googleapis.com/v0/b/knqun-8e329.appspot.com/o/${item.image}`
                "
                alt=""
                v-scroll-reveal
              />
            </div>
          </div>
          <div class="services__about">
            <h4 class="services__about--title" v-scroll-reveal>
              {{ item.title }}
            </h4>
            <p class="text-medium" v-scroll-reveal>
              {{ item.description }}
            </p>
            <router-link
              :to="`/servicio/${item.slug}`"
              class="btn button--kiiner"
              v-scroll-reveal
            >
              Saber más
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    data: null,
  },
};
</script>
