<template>
  <Layout>
    <Banner :title="title" :description="description" :cta="cta_label" />
    <Benefits :benefits="benefits" />
    <Services v-if="pages_is_active" :data="services" />
    <CallToAction />
  </Layout>
</template>

<script>
import Resources from "../services/firebaseresource";
const restResourceService = new Resources();
import Banner from "@/components/Banner.vue";
import Benefits from "@/components/Benefits.vue";
import CallToAction from "@/components/CallToActionHome.vue";
import Services from "@/components/Services.vue";
import Layout from "./Layout.vue";

export default {
  name: "Home",
  components: {
    Layout,
    Banner,
    Benefits,
    CallToAction,
    Services,
  },
  data() {
    return {
      title: null,
      description: null,
      name: null,
      banner_image: null,
      cta_label: null,
      services: [],
      benefits: {},
      pages_is_active: false,
    };
  },
  created() {
    restResourceService.getLanding("home").then((r) => {
      this.title = r.title;
      this.description = r.description;
      this.name = r.name;
      this.cta_label = r.cta_label;
      this.benefits = r.benefits;
      this.banner_image = r.banner_image;
    });
    // TODO: include this section when Sales & Marketing includes the copys
    // restResourceService.getLanding("pages").then((r) => {
    //   this.services = r;
    // });
  },
};
</script>
