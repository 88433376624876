<template>
  <Layout>
    <Banner
      v-if="data"
      :title="data.title"
      :description="data.description"
      :cta="data.title"
      :image="getImageUrl(data.image)"
      :home="false"
    />
    <section class="container pt-5 pb-3">
      <div class="services__item">
        <div class="services__cover">
          <div class="services__cover--wrap">
            <img
              class="services__cover--img"
              :src="getImageUrl(data.image)"
              alt=""
              v-scroll-reveal
            />
          </div>
        </div>
        <div class="services__about">
          <p v-scroll-reveal>
            {{ data.sub_phrase }}
          </p>
          <h4 class="services__about--title" v-scroll-reveal>
            {{ data.phrase }}
          </h4>
          <p class="text-medium" v-scroll-reveal>
            {{ data.description }}
          </p>
        </div>
      </div>
    </section>
    <section class="container pb-5 mb-5">
      <div class="row gx-4">
        <div
          v-for="(item, index) in data.values"
          :key="index"
          class="col-12 col-lg-4"
        >
          <div
            class="card card-kiiner text-center"
            v-scroll-reveal="{ delay: 200 + index }"
          >
            <div class="card-body">
              <ion-icon
                class="service__icon-value"
                name="logo-facebook"
              ></ion-icon>
              <h5 class="card-title">{{ item.title }}</h5>
              <p class="card-text">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container">
      <div class="pricing-information">
        <div class="pricing-item">
          <div class="pricing-content">
            <h3>Business Starter</h3>
            <p>
              One request at a time. Pause or cancel anytime.
            </p>
            <p class="pricing-number">
              $4,995/m
              <br />
              <span class="text-muted fs-6">
                Pause or cancel anytime
              </span>
            </p>
            <button class="button pricing w-inline-block">
              Comienza hoy
            </button>
          </div>
          <div class="pricing-includes">
            <p class="fw-bold">
              What's included:
            </p>
            <ul class="pricing-includes__list">
              <li>One request at a time</li>
              <li>Average 48 hour delivery</li>
              <li>Unlimited brands</li>
              <li>Unlimited users</li>
              <li>Unlimited stock photos via Shutterstock</li>
              <li>Easy credit-card payments</li>
              <li>Pause or cancel anytime</li>
            </ul>
          </div>
        </div>
        <div class="pricing-item">
          <div class="pricing-content">
            <h3>Business Standard</h3>
            <p>
              Double the requests. Pause or cancel anytime.
            </p>
            <p class="pricing-number">
              $7,995/m
              <br />
              <span class="text-muted fs-6">
                Pause or cancel anytime
              </span>
            </p>
            <button class="button pricing w-inline-block">
              Comienza hoy
            </button>
          </div>
          <div class="pricing-includes">
            <p class="fw-bold">
              What's included:
            </p>
            <ul class="pricing-includes__list">
              <li>Two requests at a time</li>
              <li>Average 48 hour delivery</li>
              <li>Unlimited brands</li>
              <li>Unlimited users</li>
              <li>Unlimited stock photos via Shutterstock</li>
              <li>Easy credit-card payments</li>
              <li>Pause or cancel anytime</li>
            </ul>
          </div>
        </div>
        <div class="pricing-item">
          <div class="pricing-content">
            <h3>Enterprise</h3>
            <p>
              Learn more about how DesignJoy works and how it can help you.
            </p>
            <button class="button pricing w-inline-block">
              Comienza hoy
            </button>
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>

<script>
import Resources from "../services/firebaseresource";
const restResourceService = new Resources();

import Layout from "./Layout.vue";
import Banner from "@/components/Banner.vue";

export default {
  props: ["slug"],
  components: {
    Layout,
    Banner,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.scrollToTop();
    restResourceService.getPageBySlug(this.slug).then((r) => {
      this.data = r;
      this.images = `https://firebasestorage.googleapis.com/v0/b/knqun-8e329.appspot.com/o/${r.image}`;
    });
  },
  methods: {
    getImageUrl(image) {
      if (image) {
        return `https://firebasestorage.googleapis.com/v0/b/knqun-8e329.appspot.com/o/${image}`;
      }
      return "https://firebasestorage.googleapis.com/v0/b/knqun-8e329.appspot.com/o/assets%2Fpattern-square.svg?alt=media&token=7004ab63-6158-491d-bf80-355ceb19969e";
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
