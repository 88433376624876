<template>
  <fragment>
    <NavbarVue />
    <main>
      <slot></slot>
    </main>
    <FooterVue />
  </fragment>
</template>

<script>
import NavbarVue from "@/components/Navbar.vue";
import FooterVue from "@/components/Footer.vue";

export default {
  components: {
    NavbarVue,
    FooterVue,
  },
};
</script>
