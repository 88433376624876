import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueScrollReveal from "vue-scroll-reveal";
import Fragment from "vue-fragment";
import "./firebase";
import "./assets/css/bootstrap.min.css";
import "./assets/css/styles.scss";
import "./assets/js/bootstrap.min.js";

Vue.use(Fragment.Plugin);
Vue.use(VueScrollReveal, {
  class: "v-scroll-reveal",
  duration: 1500,
  scale: 1,
  distance: "100px",
  mobile: false,
});

Vue.config.productionTip = false;

Vue.config.ignoredElements = [/^ion-/];

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
