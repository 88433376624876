<template>
  <div class="nav-wrapper" :class="{ show: showModal }">
    <nav class="main-navbar">
      <div class="container">
        <div class="nav-content">
          <router-link to="/" aria-current="page" class="name-branding">
            <img width="75" :src="logoUrl" alt="" />
          </router-link>
          <button class="btn burguer-icon" @click="openNavbar()">
            <div></div>
            <div></div>
          </button>
        </div>
      </div>
    </nav>
    <div class="nav-elements" :class="{ fade: showModal, show: showModal }">
      <div class="container menu-items-container">
        <ul class="menu-items">
          <li
            v-for="(menu, index) in menuItems"
            :key="index"
            class="animate__animated animate__slideInLeft"
            :style="`--animate-duration: 0.${(index + 2) * 10}s;`"
          >
            <router-link :to="menu.redirectTo" aria-current="page">
              {{ menu.label }}
            </router-link>
          </li>
        </ul>
        <div
          class="contact animate__animated animate__slideInLeft"
          :style="`--animate-duration: 0.${(menuItems.length + 2) * 10}s;`"
        >
          <a href="mailto:hola@kiiner.com">
            hola@kiiner.com
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openMenu: false,
      showModal: false,
      logoUrl: require("@/assets/images/logo-white.svg"),
      menuItems: [
        {
          label: "Inicio",
          redirectTo: "/",
        },
        {
          label: "Acerca de nosotros",
          redirectTo: "/nosotros",
        },
        // {
        //   label: "Nuestro Proceso",
        //   redirectTo: "/proceso",
        // },
        {
          label: "Contacto",
          redirectTo: "/contacto",
        },
      ],
    };
  },
  methods: {
    openNavbar() {
      this.showModal = !this.showModal;
      this.logoUrl = this.showModal
        ? require("@/assets/images/logo.svg")
        : require("@/assets/images/logo-white.svg");
    },
    closeMenu() {
      this.showModal = false;
    },
  },
  watch: {
    ["$route"](to, from) {
      this.closeMenu();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.closeMenu();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.closeMenu();
    next();
  },
};
</script>
