import firebase from "firebase/app";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBoRff2fCc7hb1hjjqYDCEH5IYlPsCJDP4",
  authDomain: "knqun-8e329.firebaseapp.com",
  databaseURL: "https://knqun-8e329-default-rtdb.firebaseio.com",
  projectId: "knqun-8e329",
  storageBucket: "knqun-8e329.appspot.com",
  messagingSenderId: "318599574798",
  appId: "1:318599574798:web:3f493a654ce28d062b935f",
  measurementId: "G-8QG2NC8N75",
};
firebase.initializeApp(firebaseConfig);

export default firebase;
