import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Services from "../views/Services.vue";

Vue.use(VueRouter);

const allowedSlugs = [
  "marketing-de-alto-impacto",
  "segmentacion-mercado-estrategica",
  "gestion-profesional-redes-sociales",
  "diseno-imagen-impactantes",
  "desarrollo-web",
  "fotografia-calidad-para-tu-marca",
  "posicionamiento-web",
];

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/servicio/:slug",
    name: "servicios",
    component: Services,
    props: true,
    beforeEnter: (to, from, next) => {
      const slug = to.params.slug;

      if (allowedSlugs.includes(slug)) {
        // El slug está permitido, continuar con la navegación
        next();
      } else {
        // El slug no está permitido, redirigir a una página 404
        next({
          name: "404",
          params: { pathMatch: to.path.split("/").slice(1) },
        });
      }
    },
  },
  {
    path: "/nosotros",
    name: "nosotros",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/proceso",
    name: "proceso",
    component: () => import("../views/Process.vue"),
  },
  {
    path: "/contacto",
    name: "contacto",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "*",
    redirect: { name: "404", params: { pathMatch: [""] } },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
